import {AnalyzeService} from './../../services/analyzeService';
import {bindable, inject} from 'aurelia-framework';
import {Router} from "aurelia-router";
import {DialogService} from "aurelia-dialog";
import {Prompt} from "../prompt";
import * as Fhir from "../../classes/FhirModules/Fhir";
import {IFormSetting} from "../../classes/IFormSettings";
import {ConfigService} from "../../services/ConfigService";
import {PatientChangeNotifier} from 'resources/services/PatientChangeNotifier';
import {IQuestionnaireList, QuestionnaireService} from 'resources/services/QuestionnaireService';
import {PatientService} from 'resources/services/PatientService';
import {I18N} from 'aurelia-i18n';
import {fhirEnums} from "../../classes/fhir-enums";
import {FhirService} from "../../services/FhirService";
import {PatientItem} from "../../classes/Patient/PatientItem";
import {NitTools} from "../../classes/NursitTools";
import {RuntimeInfo} from "../../classes/RuntimeInfo";

const moment = require("moment");
import QuestionnaireResponseStatus = fhirEnums.QuestionnaireResponseStatus;
import {Patient} from "../../classes/FhirModules/Fhir";
import * as environment from "../../../../config/environment.json";
import {beditPatientInfo} from "../../../views/bedit/bedit-patient-info";
import {NursingVisitsProtocol} from "../../../views/patient/nursing-visits-protocol";

@inject(Router, PatientChangeNotifier, DialogService, AnalyzeService, I18N, PatientService, FhirService)
export class patientSignalReiter {
    @bindable get patient() {
        return PatientItem.SelectedPatient;
    }

    analyzeService: AnalyzeService;
    notifier: PatientChangeNotifier;
    subscriberId: string = undefined;
    router: Router = undefined;
    dialogService: DialogService;
    showInfo: boolean = false;
    assessmentTextClass: string = "text";
    isChecking = false;
    qList: IQuestionnaireList;
    i18n: I18N;
    warningMessages: any[] = [];
    patientService: PatientService;
    fhirService: FhirService;
    isSaving: boolean = false;
    config: IFormSetting;
    infoItems: any[] = [];
    tmp;
    maxIsoAge: number = 24;
    biAndBiExWarnings: string[] = [];
    isLoading: boolean = false;
    isoConfig: IFormSetting;
    isoQuestionnaire: any;

    constructor(router: Router, notifier: PatientChangeNotifier, dialogService: DialogService, analyzeService: AnalyzeService,
                i18n: I18N, patientService: PatientService, fhirService: FhirService) {
        this.patientService = patientService;
        this.router = router;
        this.analyzeService = analyzeService;
        this.notifier = notifier;
        this.dialogService = dialogService;
        this.i18n = i18n;
        this.fhirService = fhirService;

        this.subscriberId = this.notifier.subscribe((p: PatientItem) => {
            /*this.patientChanged(p)
                .catch(error => {
                    console.warn(error.message || JSON.stringify(error));
                }) */
        });

        if (ConfigService.Debug) window["signalreiter"] = this;
    }

    async attached() {
        await QuestionnaireService.Fetch();
    }

    signalReiterOverrides: ISignalReiterConfig = undefined;

    nursingVisitsEnabled : boolean = false;
    nursingVisitsIndex : number = undefined;
    nursingVisitResubmissionColor : string = '#c35add';

    updateWarnings() {
        this.warningMessages = [];

        if (!this.patient || !this.patient.encounter) return;

        if (this.patient.flags && this.patient.flags.code && this.patient.flags.code.coding) {
            if (ConfigService.UseBarthelIndexForFlag5) {
                const mark5Flag = this.patient.flags.code.coding.find(o => o.system.endsWith('/mark_5_text')); //  FhirService.Tags.get(this.patient.encounter, 'tags/mark5');

                if (mark5Flag && mark5Flag.code) {
                    mark5Flag.code.split(',').forEach(s => this.warningMessages.push({
                        text: this.i18n.tr(s),
                        class: 'text-danger'
                    }));
                }
            }
        }

        const nursingConfig = ConfigService.GetFormSettings('nursingvisit', this.patient);
        this.nursingVisitsIndex = nursingConfig?.enabled && typeof nursingConfig.settings?.targetSignal === "number" ? nursingConfig.settings.targetSignal : undefined;
        this.nursingVisitsEnabled = nursingConfig?.enabled && nursingConfig.settings?.targetSignal;
        this.nursingVisitResubmissionColor = nursingConfig?.settings?.resubmissionWarningColor || this.nursingVisitResubmissionColor;

        const mark10 = this.patient.marks.find(o => o.index === 10);
        const assessmentConfig = ConfigService.GetFormSettings('assessment');
        const latestAssessmentDateFlagSystem = `latest${assessmentConfig.questionnaireName}Date`;
        let latestAssessmentFlag: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, latestAssessmentDateFlagSystem, false);

        // maybe the flag was not found, so compensate that by accessing the last-assessment
        if (ConfigService.UseAssessmentForSignal10) {
            try {
                if (!latestAssessmentFlag && this.patient.latestAssessment) {
                    latestAssessmentFlag = {
                        code: this.patient.latestAssessment.authored,
                        system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/${latestAssessmentDateFlagSystem}`
                    }

                    this.patient.flags.code.coding.push(latestAssessmentFlag);
                }
            } catch (err) {
                if (ConfigService.Debug)
                    console.warn(err.message || err);
            }

            if (!latestAssessmentFlag) {
                this.warningMessages.push({text: this.i18n.tr('no_assessment'), class: 'text-danger'});
            } else {
                if (mark10.red || mark10.yellow) {
                    let age = moment(new Date()).diff(latestAssessmentFlag.code, 'h');
                    let scale = this.i18n.tr('hours');
                    if (age > 24) {
                        age = Math.trunc ? Math.trunc(age / 24) : Math.round(age / 24);
                        scale = this.i18n.tr('days');
                    }

                    this.warningMessages.push({
                        text: this.i18n.tr('assessment_getting_old').replace('%AGE%', age.toString()).replace('%SCALE%', scale),
                        class: mark10.red ? 'text-danger' : 'text-warning'
                    })
                }
            }
        }

        //#region process Isolation (1)
        const mark1 = this.patient.marks.find(o => o.index === 1);
        if (mark1.checked && ConfigService.UseIsolationForFlag1) {
            const latestIsoDateFlagSystem = `latest${this.isoConfig.questionnaireName}Date`;
            let latestIsolationFlag: any = Fhir.Tools.GetOrCreateFlag(this.patient.flags, latestIsoDateFlagSystem, false);

            try {
                if (!latestIsolationFlag) {
                    const latestIsoResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, this.isoQuestionnaire.id, [QuestionnaireResponseStatus.amended, QuestionnaireResponseStatus.completed]);
                    if (latestIsoResponse) {
                        latestIsolationFlag = {
                            code: latestIsoResponse.authored,
                            system: `${NitTools.ExcludeTrailingSlash(RuntimeInfo.SystemHeader)}/${latestIsoDateFlagSystem}`
                        }

                        this.patient.flags.code.coding.push(latestIsolationFlag);
                    }
                }
            } catch (error) {
                if (ConfigService.Debug)
                    console.warn(error.message || error);
            }

            if (!latestIsolationFlag) {
                this.warningMessages.push({text: this.i18n.tr("no_iso_docu"), class: "text-danger"});
            } else {
                const isoDate = new moment(latestIsolationFlag.code).startOf('day');
                const today = new moment(new Date()).startOf('day');
                if (isoDate.isBefore(today)) {
                    this.warningMessages.push({text: this.i18n.tr("no_current_iso_docu"), class: "text-danger"});
                }
            }
        }
        //#endregion

        //#region check if there are gaps/holes between the first and the last isolation document:
        let show = true;

        // display the warning if it is not explicitely disabled:
        if (this.isoConfig.settings.hasToBeSeamless === false) show = false;

        if (ConfigService.UseIsolationForFlag1 && show) {
            let isoResponses = QuestionnaireService.GetResponsesOfType(this.patient, this.qList.QIsolationId);
            if (isoResponses && isoResponses.length > 1) {
                let firstIso = isoResponses[0]; // the 1st iso response
                let lastIso = isoResponses[isoResponses.length - 1]; // the last iso response

                // the number of expected responses when each day one is filled
                let expectedCount = moment(lastIso.authored).diff(moment(firstIso.authored), "d") + 2;

                // create a list of all dates where isolations would be expected
                let dates = [];
                for (let i = 0; i < expectedCount; i++) {
                    dates.push({date: moment(firstIso.authored).add(i, "day").format("YYYY/MM/DD"), found: false});
                }

                for (let i = 0; i < dates.length; i++) {
                    let dateItem = dates[i];
                    let existing = isoResponses.find(o => moment(o.authored).format("YYYY/MM/DD") === dateItem.date);
                    if (existing && (existing.status === 'completed' || existing.status === 'amended')) dateItem.found = true;
                }

                let missingCount = dates.filter(o => o.found === false).length;
                if (missingCount > 0) {
                    this.warningMessages.push({
                        text: this.i18n.tr("missing_isolations_count").replace(/%COUNT%/, missingCount.toString()),
                        class: "text-danger"
                    });
                }

                let notCompletedCount = isoResponses.filter(o => o.status === 'in-progress').length;
                if (notCompletedCount > 0) {
                    this.warningMessages.push({
                        text: this.i18n.tr("not_completed_isolations_count").replace(/%COUNT%/, notCompletedCount.toString()),
                        class: "text-warning"
                    });
                }
            }
        }
        //#endregion

        //#region update the signals that are attached to a specific Questionnaire
        // 1. check if a config setting exists in features.signals
        if (this.signalReiterOverrides?.enabled && NitTools.IsArray(this.signalReiterOverrides.signals)) {
            const getHourValue = function (input) {
                let result = NaN;
                if (input) {
                    if (typeof input === "string") {
                        if (String(input).endsWith("h")) {
                            result = parseFloat(String(input).replace("h", ""));
                        } else if (String(input).endsWith("m")) {
                            result = parseFloat(String(input).replace("h", "")) / 60;
                        } else {
                            result = parseFloat(String(input));
                        }
                    } else if (typeof input === "number") {
                        result = <number>input;
                    }
                }

                return result;
            }

            for (const signalCfg of this.signalReiterOverrides.signals) {
                const mark = this.patient.marks?.find(o => o.index === signalCfg.signal);
                if (!mark) {
                    console.warn(`Current Patient has no instance in marks[${signalCfg.signal}]`);
                    continue;
                }

                const updateFlag = () => {
                    //as a workaround we set the flags directly in here. Should have happened when mark.XXX had been set, but does not seem to happen.
                    const flagChecked = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${signalCfg.signal}`, true);
                    const flagRed = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${signalCfg.signal}_red`, true);
                    const flagYellow = Fhir.Tools.GetOrCreateFlag(this.patient.flags, `${NitTools.ExcludeTrailingSlash(environment.nursItStructureDefinition)}/marks/mark_${signalCfg.signal}_yellow`, true);
                    if (flagChecked)
                        flagChecked.code = mark.checked ? 'true' : 'false';
                    if (flagRed)
                        flagRed.code = mark.red ? 'true' : 'false';
                    if (flagYellow)
                        flagYellow.code = mark.yellow ? 'true' : 'false';
                }

                const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(signalCfg.questionnaireName);
                if (!questionnaire) {
                    console.warn(`Questionnaire for Signal ${signalCfg.signal} with name "${signalCfg.questionnaireName}" not found!`);
                    continue;
                }

                const infoItem = this.infoItems.find(o => o.id === String(signalCfg.signal));
                if (infoItem) {
                    infoItem.text = questionnaire.title;
                }

                //if (!signalCfg.isToggle || (signalCfg.isToggle && mark.checked) ) {
                // only process when this signal is either not a toggle or when the toggle is set to "on"

                let processValues = true;
                if (!signalCfg.isToggle) {
                    // this is not a toggleable signal, so always process the values
                    mark.checked = true;
                    processValues = true;
                } else {
                    // this is toggleable, so get the current state from the flags
                    processValues = mark.checked;
                }

                if (!processValues) {
                    mark.red = false;
                    mark.yellow = false;

                    updateFlag();
                    continue;
                }

                let redAfterHours: number = NaN;
                let yellowAfterHours: number = NaN;
                // when the redAfter is a string, check whether it is minutes or hours
                try {
                    redAfterHours = getHourValue(signalCfg.redAfter);
                    yellowAfterHours = getHourValue(signalCfg.yellowAfter);
                } catch (e) {
                    console.warn(e);
                    redAfterHours = NaN;
                    yellowAfterHours = NaN;
                }

                if (redAfterHours !== NaN && yellowAfterHours !== NaN) {
                    const lastResponse = QuestionnaireService.GetLatestResponseOfType(this.patient, questionnaire.id, [QuestionnaireResponseStatus.completed, QuestionnaireResponseStatus.amended]);
                    if (lastResponse && lastResponse.authored) {
                        const age = moment(new Date()).diff(lastResponse.authored, "h", true);
                        console.debug(`Age of Response is: ${age}h. Should be RED after: ${redAfterHours}h and YELLOW after ${yellowAfterHours}h`);

                        mark.yellow = age > yellowAfterHours;
                        mark.red = age >= redAfterHours;
                        const ageRounded = moment(new Date()).diff(lastResponse.authored, "h", false);

                        if (mark.red || mark.yellow) {
                            this.warningMessages.push({
                                text: this.i18n.tr('response_getting_old')
                                        .replace('%NAME%', questionnaire.title)
                                        .replace('%AGE%', ageRounded)
                                        + ` (${signalCfg.signal})`,
                                class: mark.red ? 'text-danger' : 'text-warning'
                            });
                        }
                    } else {
                        mark.yellow = true;
                        mark.red = true;
                        this.warningMessages.push({
                            text: this.i18n.tr('response_not_exist')
                                .replace('%NAME%', questionnaire.title)
                                + ` (${signalCfg.signal})`,
                            class: 'text-danger'
                        });
                    }
                } // if redAfterHours !== NaN ..

                updateFlag();
            } // for const signalCfg ..

            this.notifier.notify(this.patient, this.patient.flags);
        }
        //#endregion

        //#region update the Signal specified by the nursing visit, using the colorOverride property
        if (this.nursingVisitsEnabled && NitTools.IsArray(this.infoItems)) {
            const nursingMark = this.patient.marks.find(o=>o.index === nursingConfig.settings.targetSignal);
            const infoItem = this.infoItems[this.patient.marks.indexOf(nursingMark)];

            if (infoItem) {
                infoItem.text = this.i18n.tr('nursingVisitsProtocol');
            } else {
                console.warn(`No InfoItem found for mark: `, nursingMark);
            }

            if (nursingMark) {
                nursingMark.checked = true;
                nursingMark.overrideColor = NursingVisitsProtocol.GetSignalColor(this.patient, this.nursingVisitResubmissionColor);
            }

            if (NursingVisitsProtocol.IsExpired(this.patient)) {
                let resubmissionMessage : string = this.i18n.tr("nursingProtocolResubmissionInfo");

                resubmissionMessage = NitTools.Format(resubmissionMessage, moment(NursingVisitsProtocol.ExpirationDate(this.patient)).format(RuntimeInfo.DateFormat));
                this.warningMessages.push({text: resubmissionMessage, class: "text-danger"});
            }
        }
        //#endregion
    }

    detached() {
        this.notifier.unsubscribe(this.subscriberId);
    }

    currentConfig;

    async patientChanged(newPatient: PatientItem) {
        // reset display
        $(".patient-signal-reiter a.active").removeClass("active");
        this.biAndBiExWarnings = [];
        if (!newPatient) return;

        this.currentConfig = await ConfigService.LoadConfigOverride(newPatient.ward, newPatient);
        await PatientItem.ValidateMark5(newPatient, false);
        try {
            this.isLoading = true;
            this.qList = await QuestionnaireService.GetQuestionnaireIds();
            let addInfo = await QuestionnaireService.GetQuestionnaireById(this.qList.QAdditionalInfoId);
            this.buildInfoList(addInfo);

            // to be able to display the warning for isolation correctly, we need to load all the isolations
            this.isoQuestionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(PatientItem.GetQuestionnaireName(this.patient, 'isolation'));
            this.isoConfig = ConfigService.GetFormSettings('isolation');

            // get the overrides for the signalreiters if any
            this.signalReiterOverrides = this.currentConfig?.features?.signalreiter;

            // update the warning-messages:
            this.updateWarnings();
        } catch (e) {
            console.warn(e);
        } finally {
            this.isLoading = false;
        }
    }

    toggleInfo() {
        this.showInfo = !this.showInfo;
    }

    processMark1() {
        if (!ConfigService.UseIsolationForFlag1)
            return;

        const m1 = this.patient.mark(1);
        if (m1.checked) {
            if (m1.red) {
                /* Situation 2: Isolation existiert schon, Marker ist rot, muss neue Tag dokumentiert werden.
                Im Grunde das gleich wie Situation 1, user muss gefragt werden ob er dokumentieren möchte
                oder direkt zurücknehmen (ohne Dokumentation für den Tag) */
                /*: user klickt,  user muss gefragt werden ob er dokumentieren möchte oder direkt zurücknehmen,
                    wenn stoppen: mark1 = false, mark1.red = false
                    wenn doku: ab zu iso */

                console.debug(`Situation 2, mark 1 red: ${m1.red}`);

                this.dialogService.open({
                    viewModel: Prompt,
                    model: {
                        message: this.i18n.tr("iso_doku_or_revoke"),
                        title: this.i18n.tr('confirm'),
                        yesText: this.i18n.tr("document_it"),
                        noText: this.i18n.tr('revoke')
                    },
                    lock: true
                })
                    .whenClosed(response => {
                        if (response.wasCancelled) {
                            this.doToggleSignal(1, true, false);
                        } else {
                            m1.checked = true;
                            m1.red = true;

                            this.router.navigate(`#/${fhirEnums.ResourceType.encounter}/${this.patient.encounterId}/isolation`);
                        }
                    })
                    .catch(e => {
                        console.debug(e.output);
                    });

                return;
            } else {
                /*
                Situation 3: Isolation exisitert schon, marker ist aber nicht rot,
                d.h. für den Tag wurde schon dokumentiert. Dann muss der User nur gefragt werden,
                ob er die Isolation zurücknehmen möchte. */
                // console.debug(`S3, mark 1 red: ${m1.red}`);
                console.debug(`Situation 3, mark 1 red: ${m1.red}`);
                this.dialogService.open({
                    viewModel: Prompt,
                    model: {
                        message: `<div>${this.i18n.tr("confirm_revoke_iso_doku")}</div><br /><div>${this.i18n.tr("when_described")}</div>`,
                        title: this.i18n.tr('confirm'),
                        yesText: this.i18n.tr("yes"),
                        noText: this.i18n.tr('no'),
                        /* inputType: 'date',
                        value: new Date() */
                    },
                    lock: true
                })
                    .whenClosed(async response => {
                        if (response.wasCancelled) {
                            // noop
                        } else {
                            // yes
                            await this.doToggleSignal(1, true, false /*, new Date(response.output)*/);
                        }
                    })
                    .catch(e => {
                        console.debug(e.output);
                    });

                return;
            }
        } else {
            let isolationId = QuestionnaireService.__listResult.QIsolationId;

            if (this.patientService.getQuestionnaireListForCombo(isolationId, this.patient).length === 0) {
                /* Situation 1: Neue Isolation - Patient war noch nicht isoliert, es existiert kein Iso-Formular:
                   User klickt auf Marker. Marker wird voll und sofort rot, weil es eben für den heutigen Tag nicht
                   ausgefühlt ist.  Man bleibt auf der Übersichtseiten (spring also nicht zu Formular);

                   Wenn man auf den Roten Marker klickt gibt es drei Möglichkeiten:
                   - User hat sich vertippt (war gar keine Isolation)
                   - User möchte den Tag dokumentieren */
                console.debug(`Situation 1: mark 1 red: ${m1.red}`);

                m1.red = true;
                this.doToggleSignal(1, true, true);
            } else {
                /* Situation 4: Weitere Isolation während des Auffenthalts:
                Patient war während des Aufenthalt schon mal isoliert, wurde ein Paar Tage bereits aus der Isolation rausgenommen.
                muss aber wieder rein. Habe extra nachgefragt:
                diese Situation ist aus Pflegesicht wie Situation 1 behandelt. */

                /*: -> User klickt, Signalreiter muß rot werden, da keine Isolation für heute vorhanden ist, is_has_been_set => true */
                console.debug(`Situation 4, mark 1 red: ${m1.red}`);

                this.doToggleSignal(1, true);
            }
        }
    }

    async toggleSignal(signal, handled: boolean = false) {
        if (this.isSaving || this.patient.isOffline) return;
        await this.doToggleSignal(signal, handled);
    }

    async askWhatToDo(signalCfg: ISignalReiterConfigSignal) {
        const mark = this.patient.mark(signalCfg.signal);
        const info = this.infoItems.find(o => o.id === String(signalCfg.signal));
        this.dialogService.open({
            viewModel: Prompt,
            model: {
                message: this.i18n.tr('confirm_revoke_doku').replace('%NAME%', info?.text),
                title: this.i18n.tr('confirm'),
                showNo: true,
                showCancel: true,
                showOther: true,
                otherText: this.i18n.tr("document_it"),
                yesText: this.i18n.tr("revoke"),
                noText: this.i18n.tr("abort")
            },
            lock: true
        })
            .whenClosed(async response => {
                if (response.wasCancelled) {
                    // abbrechen
                } else {
                    if (response.output === "other") {
                        // dokumentieren
                        const questionnaire = QuestionnaireService.GetQuestionnaireByNameDirect(signalCfg.questionnaireName);
                        if (!questionnaire) {
                            console.warn(`Questionnaire for Signal ${signalCfg.signal} with name "${signalCfg.questionnaireName}" not found!`);

                            return;
                        }

                        this.router.navigateToRoute('questionnaire', {
                            questionnaireId: questionnaire.id, name: (questionnaire.name || questionnaire.title)
                        });

                    } else if (response.output === true) {
                        // stoppen
                        await this.doToggleSignal(signalCfg.signal, true, false);
                    }
                }
            })
            .catch(e => {
                console.debug(e.output);
            });
    }

    async doToggleSignal(signal: number, handled: boolean = false, forcedValue?: boolean, when?: Date) {
        if (this.nursingVisitsEnabled && signal === this.nursingVisitsIndex) {
            this.router.navigateToRoute('nursingvisit');

            return;
        }

        const mark = this.patient.mark(signal);

        try {
            const signalOverride = this.signalReiterOverrides?.signals?.find(o => o.signal === signal);
            if (signalOverride && typeof forcedValue === "undefined") {
                if (signalOverride.isToggle) {
                    // this signal could be toggled on and off, so ask the user what to do when the mark is active
                    if (mark.checked) {
                        await this.askWhatToDo(signalOverride);

                        return;
                    } else {
                        forcedValue = true;
                    }
                } else {
                    // this is not a toggle simply switch to the questionnaire view ..
                    if (signalOverride.questionnaireName) {  // .. when the questionnaire has been set
                        const q = QuestionnaireService.GetQuestionnaireByNameDirect(signalOverride.questionnaireName);
                        if (q) {
                            this.router.navigateToRoute('questionnaire', {
                                questionnaireId: q.id,
                                name: (q.name || q.title)
                            });
                        }

                        return;
                    }
                }
            }
        } catch {
            debugger;
        }

        try {
            if (document.activeElement && typeof (<any>document.activeElement).blur === "function")
                (<any>document.activeElement).blur();
        } catch {
        }

        if (signal === 5 && ConfigService.UseBarthelIndexForFlag5) {
            // if barthel and biEx are enabled then go to the analysis page, or else simple toggle the button
            this.router.navigate('analysis');
            return;
        } else if (signal === 10 && ConfigService.UseAssessmentForSignal10) {
            this.router.navigate(`#/Encounter/${this.patient.encounterId}/assessment`);
            return;
        }

        if (this.patient.isOffline) return;
        if (!this.patient.flags) {
            await PatientItem.ReadAndCalculateMarksAndVisitNumber(this.patient);
        }

        if ((signal === 1 && !handled) && ConfigService.UseIsolationForFlag1) {
            this.processMark1();
            return;
        }

        if (typeof forcedValue === "boolean") {
            mark.checked = forcedValue;
        } else if (typeof forcedValue === "undefined") {
            mark.checked = !mark.checked;
        }

        this.isSaving = true;
        try {
            // update flag and additional info on the server
            // Fhir.Tools.SyncFlagsFromPatient(this.patient);
            // await this.fhirService.bundle([this.patient.flags, ai], HTTPVerb.put);
            await this.analyzeService.validateMarks(this.patient);
            await this.fhirService.update(this.patient.flags);
            this.notifier?.notify(this.patient, this.patient);
            this.notifier?.notify(this.patient, this.patient.flags);
        } catch (error) {
            let msg = error.message || error.content ? JSON.parse(error.content) : JSON.stringify(error);
            console.warn(msg);
        } finally {
            this.updateWarnings();
            this.isSaving = false;
        }
    }

    buildInfoList(q: any) {
        if (!q) return;
        this.tmp = q;
        this.infoItems = [];
        q.item.filter(o => o.type === fhirEnums.QuestionnaireItemType.boolean).forEach((item: any) => {
            if (item.linkId && item.text) {
                this.infoItems.push({
                    id: item.linkId.indexOf('_') > -1 ? item.linkId.split('_')[1] : item.linkId,
                    text: item.text
                });
            }
        })
    }

    async toggleIsolation() {
        if (!ConfigService.UseIsolationForFlag1)
            return;

        const m1 = this.patient.mark(1);
        if (m1.checked) {
            if (m1.red) {
                /* Situation 2: Isolation existiert schon, Marker ist rot, muss neue Tag dokumentiert werden.
                    Im Grunde das gleich wie Situation 1, user muss gefragt werden ob er dokumentieren möchte
                    oder direkt zurücknehmen (ohne Dokumentation für den Tag) */
                //                alert("Situation 2, iso + mark 1 rot");
                this.dialogService.open({
                    viewModel: Prompt,
                    model: {
                        message: this.i18n.tr("confirm_revoke_iso_doku"),
                        title: this.i18n.tr('confirm'),
                        yesText: this.i18n.tr("yes"),
                        noText: this.i18n.tr('no')
                    },
                    lock: true
                })
                    .whenClosed(response => {
                        if (response.wasCancelled) {
                            // noop
                        } else {
                            // yes
                            // this.doToggleSignal(1, true, false);

                            m1.checked = false;
                            m1.red = false;
                            this.notifier.notify(this.patient);
                        }
                    })
                    .catch(e => {
                        console.debug(e.output);
                    });

            } else {
                /*
                Situation 3: Isolation exisitert schon, marker ist aber nicht rot,
                d.h. für den Tag wurde schon dokumentiert. Dann muss der User nur gefragt werden,
                ob er die Isolation zurücknehmen möchte. */
                this.dialogService.open({
                    viewModel: Prompt,
                    model: {
                        message: this.i18n.tr("confirm_revoke_iso_doku"),
                        title: this.i18n.tr('confirm'),
                        yesText: this.i18n.tr("yes"),
                        noText: this.i18n.tr('no')
                    },
                    lock: true
                })
                    .whenClosed(response => {
                        if (response.wasCancelled) {
                            // noop
                        } else {
                            // yes
                            this.doToggleSignal(1, true, false);
                        }
                    })
                    .catch(e => {
                        console.debug(e.output);
                    });
            }
        } else {
            if (QuestionnaireService.GetResponsesOfType(this.patient, this.qList.QIsolationId).length === 0) {
                /* Situation 1: Neue Isolation - Patient war noch nicht isoliert, es existiert kein Iso-Formular:
                               User klickt auf Marker. Marker wird voll und sofort rot, weil es eben für den heutigen Tag nicht
                               ausgefühlt ist.  Man bleibt auf der Übersichtseiten (spring also nicht zu Formular);

                               Wenn man auf den Roten Marker klickt gibt es drei Möglichkeiten:
                               - User hat sich vertippt (war gar keine Isolation)
                               - User möchte den Tag dokumentieren */
                m1.checked = true;
                m1.red = true;
                this.notifier.notify(this.patient);
                // alert("Situation 1, keine Isolation vorhanden");
            } else {
                m1.checked = true;
                m1.red = true;
                this.notifier.notify(this.patient);
                /* Situation 4: Weitere Isolation während des Auffenthalts:
                        Patient war während des Aufenthalt schon mal isoliert, wurde ein Paar Tage bereits aus der Isolation rausgenommen.
                        muss aber wieder rein. Habe extra nachgefragt:
                        diese Situation ist aus Pflegesicht wie Situation 1 behandelt. */
                // alert("Situation 4 - isos vorhanden");
            }
        }
    }
}

export interface ISignalReiterConfig {
    enabled?: boolean;
    signals?: ISignalReiterConfigSignal[];
}

export interface ISignalReiterConfigSignal {
    signal: number;
    questionnaireName: string;
    redAfter?: string | number;
    yellowAfter?: string | number;
    isToggle?: boolean;
}
